import React from 'react'
import ReactDOM from 'react-dom/client'
// import { Provider } from 'react-redux'
// import './firebase'
// import './index.css'
// import App from './App'
// import store from './redux/store'
// import reportWebVitals from './reportWebVitals'
import './App.css'
import logo from './assets/logo-banner.jpg'
import { config } from "config"
const redirectUrl = config.redirectUrl

const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// )
root.render(
  <React.StrictMode>
    <div>
      <a href="https://blockchaintrust.pro" target="_blank" rel="noreferrer">
        <img
          src={logo}
          className="logo Blockchaintrust"
          alt="Blockchaintrust logo"
        />
      </a>
    </div>
    <h1>Blockchaintrust</h1>
    <p>This site is moved to this url</p>
    <p>{redirectUrl}</p>
    <p>
      <a href={redirectUrl}>
        Click here to Go There
      </a>
    </p>
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
