const env = process.env.REACT_APP_ENV || 'uat'

const prodEnv = {
  websiteName: 'Blockchaintrust Pro',
  description: 'blockchaintrust.pro',
  apiUrl: 'https://api.blockchaintrust.pro',
  contractExplorerUrl: 'https://polygonscan.com',
  forumPayUrl: 'https://widget.forumpay.com',
  forumPayWidgetUrl: 'https://widget.forumpay.com/pay/order',
  forumPayMerchantId: 'db72598b-fbcb-4b1d-bee2-7841ccfcde82',
  redirectUrl: 'https://reseller.blockchaintrust.pro',
  env: 'prod',
}

const uatEnv = {
  websiteName: 'UAT Blockchaintrust Pro',
  description: 'blockchaintrust.pro',
  apiUrl: 'https://uat.blockchaintrust.pro',
  // apiUrl: 'http://localhost:2000',
  contractExplorerUrl: 'https://mumbai.polygonscan.com',
  forumPayUrl: 'https://sandbox.forumpay.com',
  forumPayWidgetUrl: 'https://sandbox.forumpay.com/pay',
  forumPayMerchantId: 'b3c2453a-e749-4c28-bc5f-57705ea3eaef',
  redirectUrl: 'https://uat.reseller.blockchaintrust.pro',
  env: 'uat',
}

const config = env === 'prod' ? prodEnv : uatEnv

export { config }
